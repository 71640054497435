// list of services with mutated gateways
const changedServices = []

const pushChangedService = serviceUid => changedServices.push(serviceUid)
const popChangedServices = serviceUid => {
  const index = changedServices.indexOf(serviceUid)
  if (index !== -1) {
    changedServices.splice(index, 1)
  }
}

const getChangedServices = () => changedServices

export default {
  pushChangedService,
  popChangedServices,
  getChangedServices,
}
