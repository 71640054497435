<template>
  <div>
    <b-overlay
      class="position-relative d-flex"
      :show="isProcessing"
    >
      <div ref="df">
        <b-row>
          <b-col md="5">
            <Services
              @onServiceSelected="service => currentService = service"
              @setGateways="setGateways"
              @processing="value => isProcessing = value"
            />
          </b-col>

          <!-- Top Bar -->
          <b-col
            md="7"
            class="pl-4"
          >
            <b-row class="top-bar d-flex justify-content-between align-items-baseline">
              <b-col md="7">
                <div class="d-flex align-items-baseline">
                  <b-form-group
                    label="Module"
                    class="w-50 mr-1"
                  >
                    <v-select
                      v-model="currentModule"
                      class="w-100"
                      :options="modules"
                      :clearable="false"
                      @input="changeModule"
                    />
                  </b-form-group>

                  <b-button-group>
                    <!-- Add Module Button -->
                    <span v-b-tooltip.hover="currentService ? 'Add Module' : 'Create a service first'">
                      <b-button
                        variant="primary"
                        :disabled="!currentService"
                        @click="addNewModule()"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </span>

                    <!-- Save Module Button -->
                    <b-button
                      v-if="currentService"
                      v-b-tooltip.hover="'Save Module'"
                      variant="success"
                      class="btn-save"
                      @click="onSave"
                    >
                      <feather-icon icon="SaveIcon" />
                    </b-button>

                    <!-- Delete Module Button -->
                    <b-button
                      v-if="currentService"
                      v-b-tooltip.hover="'Delete Module'"
                      v-b-modal.gateway-delete-confirm-modal
                      variant="danger"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>

                  </b-button-group>
                </div>
              </b-col>

              <!-- Buttons -->
              <b-col
                md="5"
                class="d-flex justify-content-end pr-5"
              >
                <b-button
                  v-b-tooltip.hover="'Guide'"
                  variant="warning"
                  class="mr-2"
                  @click="$bvModal.show('drawflow-guide-modal')"
                >
                  <feather-icon icon="HelpCircleIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Nodes to Drag -->
        <div
          class="d-flex justify-content-between align-items-center position-absolute"
          :style="'z-index: 3'"
        />

        <!-- Drawflow -->
        <div class="wrapper">
          <div class="col-right">
            <div
              id="drawflow"
              ref="drawflow"
              @drop="drop"
              @dragover="allowDrop"
            >
              <div class="entities overflow-auto">
                <div
                  v-for="entity of ENTITIES"
                  :key="entity.value"
                  class="drag-drawflow border-right pr-1"
                  :draggable="true"
                  :data-node="entity.value"
                  @dragstart="drag"
                  @drag="positionMobile"
                  @dragend="drop"
                >
                  <span>{{ entity.label }}</span>
                </div>
              </div>
              <b-button
                v-if="isNewModule"
                v-b-modal.confirm-clear-modal
                v-b-tooltip.hover="'Clear'"
                class="position-absolute small-btn btn-clear"
                size="sm"
                variant="danger"
              >
                <feather-icon
                  icon="DeleteIcon"
                  class="cursor-pointer"
                />
              </b-button>

              <b-button
                v-b-tooltip.hover="'Toggle Fullscreen'"
                class="position-absolute small-btn btn-maximize"
                size="sm"
                variant="primary"
                @click="toggleFullScreen('df')"
              >
                <feather-icon
                  icon="Maximize2Icon"
                  class="cursor-pointer"
                />
              </b-button>

              <div class="btn-lock">
                <i
                  v-if="isLocked"
                  id="unlock"
                  v-b-tooltip.hover="'Enable Editing'"
                  class="fas fa-lock-open"
                  @click="() => { editor.editor_mode='edit'; isLocked=false}"
                />
                <i
                  v-else
                  id="lock"
                  v-b-tooltip.hover="'Disable Editing'"
                  class="fas fa-lock"
                  @click="() => { editor.editor_mode = 'fixed'; isLocked=true}"
                />

                <feather-icon
                  v-b-tooltip.hover="isNewModule ? 'Save the module before exporting' : 'Export Module'"
                  icon="DownloadIcon"
                  size="24"
                  class="mx-1"
                  :class="isNewModule ? 'text-dark cursor-default' : 'text-white cursor-pointer'"
                  @click="isNewModule ? '' : exportDrawflow()"
                />

                <label
                  v-b-tooltip.hover="'Import Module'"
                  for="json-import"
                  class="cursor-pointer mb-0 text-white"
                >
                  <feather-icon
                    icon="UploadIcon"
                    size="24"
                  />
                </label>

                <b-form-file
                  id="json-import"
                  v-model="jsonImportFile"
                  class="hidden"
                  @change="importDrawflow"
                />
              </div>
              <div class="bar-zoom">
                <i
                  v-b-tooltip.hover="'Zoom Out'"
                  class="fas fa-search-minus cursor-pointer"
                  @click="editor.zoom_out()"
                />
                <i
                  v-b-tooltip.hover="'Reset'"
                  class="fas fa-search mx-1 cursor-pointer"
                  @click="editor.zoom_reset()"
                />
                <i
                  v-b-tooltip.hover="'Zoom In'"
                  class="fas fa-search-plus cursor-pointer"
                  @click="editor.zoom_in()"
                />
              </div>
              <div class="import-export" />

              <b-alert
                v-if=" !isProcessing && !currentService"
                class="no-services my-4"
                variant="danger"
                show
              >
                <p class="ml-1">
                  No Services Found!
                </p>
              </b-alert>
            </div>
          </div>
        </div>

        <!-- Modals -->
        <guide-modal />

        <!-- Delete Module Confirm Modal -->
        <confirm-modal
          v-if="currentModule"
          modal="gateway-delete-confirm-modal"
          :title="`Delete ${currentModule}?`"
          :is-static="true"
          :description="`Are you sure you want to delete ${currentModule}?`"
          @confirm="onDeleteModule"
        />

        <!-- Shareable Flow Delete Confirm Modal -->
        <confirm-modal
          modal="confirm-delete-shareable-modal"
          title="Are you sure?"
          description="This module contains shareable workflows which might affect other modules. Do you want to proceed?"
          ok-title="Confirm"
          :is-static="true"
          @confirm="onRemoveConfirmed"
        />

        <!-- Clear Module Confirm Modal -->
        <confirm-modal
          modal="confirm-clear-modal"
          title="Clear Module?"
          ok-title="Clear"
          :is-static="true"
          description="All your unsaved changes will be lost. Do you want to proceed?"
          @confirm="clearCurrentModule"
        />

        <!-- Delete Node Confirm Modal -->
        <confirm-modal
          modal="confirm-delete-modal"
          title="Delete Node?"
          ok-title="Delete"
          :is-static="true"
          description="If you delete this node, you cannot undo this action. Do you want to proceed?"
          @confirm="removeNode"
        />

        <!-- Confirm Modal for Unconnected Nodes -->
        <confirm-modal
          modal="unconnected-node-confirm-modal"
          title="Unconnected Nodes"
          ok-title="Confirm"
          :is-static="true"
          description="There are one or many unconnected nodes, if you confirm, they will be permanently deleted. Do you wish to continue?"
          @confirm="updateDrawflow"
        />

        <!-- Node description modal -->
        <show-node-description-modal
          :properties="currentNodeProperty"
        />

        <!-- Node properties modal -->
        <component
          :is="modalComponent"
          :properties="currentNodeProperty"
          :node="selectedNodeObj"
          @node-properties="setNodeProperties"
        />
      </div>

      <!-- Interactive Actions -->
      <div class="interactive-wrapper">
        <b-tabs
          v-if="showChat"
          class="position-absolute interactive-actions"
        >
          <b-tab title="Chat">
            <ChatIde
              v-if="currentRoomId"
              :room-id="currentRoomId"
              @hide-chat="showChat = false"
            />

            <b-card v-else>
              <b-alert
                variant="warning"
                class="p-1"
                show
              >
                <p>Save the module to interact with Chat</p>
              </b-alert>
            </b-card>
          </b-tab>

          <b-tab
            title="Response Explorer"
            lazy
          >
            <response-explorer
              ref="responseExplorer"
              :selected-node="selectedNodeObj?.uuid"
              :nodes="gateways[currentModule]"
            >
              <b-button
                v-b-tooltip.hover="'Toggle Fullscreen'"
                class="small-btn"
                size="sm"
                variant="primary"
                @click="toggleFullScreen('responseExplorer')"
              >
                <feather-icon
                  icon="Maximize2Icon"
                  class="cursor-pointer"
                />
              </b-button>
            </response-explorer>
          </b-tab>
        </b-tabs>
        <!--Button-->
        <div class="w-100">
          <span
            v-b-tooltip.left.hover="isNewModule ? 'Create and save a flow to proceed' : ''"
            class="show-chat-btn"
          >
            <b-button
              v-b-tooltip.bottom.hover="showChat ? 'Hide Chat' : 'Show Chat'"
              :variant="showChat ? 'danger' : 'primary'"
              :class="{ active: showChat }"
              :disabled="isNewModule"
              @click="showChat = !showChat"
            >
              <feather-icon
                :icon="showChat ? 'XIcon' : 'MessageCircleIcon'"
                size="24"
              />
            </b-button>
          </span>
        </div>
        <!--Button-->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  VBTooltip, BFormInput, BButtonGroup, BButton, BTabs, BTab, BFormGroup, BRow, BCol, BCard, BAlert, BOverlay, BFormFile, BBadge,
} from 'bootstrap-vue'
import Drawflow from 'drawflow'
import { capitalize } from 'lodash'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ChatIde from '@/views/ai-ide/chat-ide/index.vue'
import { getUserData } from '@/auth/utils'
import GuideModal from './components/GuideModal.vue'
import useDrawflow, { closeContextMenu } from './useDrawflow'
import getNode, { getTemplate } from './shapes'
import WorkflowNodeModal from './components/WorkflowNodeModal.vue'
import GptNodeModal from './components/GptNodeModal.vue'
import DbNodeModal from './components/DbNodeModal.vue'
import HttpNodeModal from './components/HttpNodeModal.vue'
import NodeDescriptionModal from './components/NodeDescriptionModal.vue'
import ConditionalNodeModal from './components/ConditionalNodeModal.vue'
import GptConditionalNodeModal from './components/GptConditionalNodeModal.vue'
import FileNodeModal from './components/FileNodeModal.vue'
import ChatOllamaNodeModal from './components/ChatOllamaNodeModal.vue'
import ShowNodeDescriptionModal from './components/ShowNodeDescriptionModal.vue'
import ResponseExplorer from './response-explorer/Index.vue'
import Services from './components/Services.vue'
import { ENTITIES, NODE_TYPES } from './nodeConstants'
import ShareNodeModal from './components/ShareNodeModal.vue'
import SharedWorkflowNodeModal from './components/SharedWorkflowNodeModal.vue'
import EmailNodeModal from './components/EmailNodeModal.vue'
import ScraperNodeModal from './components/ScraperNodeModal.vue'
import useResponseExplorer from './useResponseExplorer'
import helpers from './helpers'
import useServices from './useServices'

import './styles/beautiful.scss'
import './styles/drawflow.scss'
import './styles/shapes.scss'

const { pushResponseMessage } = useResponseExplorer()
const { pushChangedService } = useServices

const {
  saveDrawflow, getEntityType, toggleContextMenu, onNodeUnselected, onNodeRemoved, bulkNodeDelete,
} = useDrawflow

const {
  getNodeClass, nodeHasOnlyDescription, multipleInputDisallowed, multipleOutputDisallowed, hasOutputs, setModalComponent,
} = helpers

export default {
  components: {
    vSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BBadge,
    BFormFile,
    BTabs,
    BTab,
    BCard,
    BAlert,
    BOverlay,
    ResponseExplorer,
    GuideModal,
    WorkflowNodeModal,
    GptNodeModal,
    DbNodeModal,
    HttpNodeModal,
    NodeDescriptionModal,
    ConditionalNodeModal,
    GptConditionalNodeModal,
    ChatIde,
    Services,
    ShowNodeDescriptionModal,
    FileNodeModal,
    ChatOllamaNodeModal,
    ShareNodeModal,
    SharedWorkflowNodeModal,
    EmailNodeModal,
    ScraperNodeModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      ENTITIES,
      editor: null,
      mobile_last_move: null,
      mobile_item_selec: '',
      transform: '',
      isLocked: false,
      selectedNode: null,
      selectedNodeObj: null,
      modules: [],
      currentModule: null,
      properties: {},
      currentNode: null,
      currentNodeProperty: {},
      modalComponent: null,
      gateways: {},
      roomIds: {},
      currentRoomId: null,
      socket: null,
      currentService: null,
      self: getUserData(),
      showChat: false,
      isProcessing: false,
      removedConnections: [],
      jsonImportFile: null,
    }
  },
  computed: {
    currentGateway() {
      return this.$store.state.dataFlow.currentModule
    },
    isNewModule() {
      return this.currentModule && !Object.keys(this.gateways).includes(this.currentModule)
    },
  },
  watch: {
    currentModule(value) {
      this.$store.commit('dataFlow/SET_CURRENT_MODULE', value)
    },
  },
  mounted() {
    this.initializeDrawflow()

    // Events!
    this.editor.on('click', e => {
      if (e.which === 1 && this.selectedNode) {
        const nodeClasses = ['box', 'node-label', 'drawflow-node']
        const nodeClassList = e.target.classList

        if (nodeClasses.some(cl => nodeClassList.contains(cl))) {
          closeContextMenu(this.selectedNode)
        }
      }
    })

    this.editor.on('nodeSelected', id => {
      this.showButtons(id)
    })

    this.editor.on('contextmenu', () => {
      if (this.selectedNode) {
        toggleContextMenu(this.selectedNode, this.currentNode)

        // Delete Node
        const delBtn = this.selectedNode.querySelector('.df-delete')
        delBtn.addEventListener('click', this.onRemoveNodeClicked)

        // share node
        const shareBtn = this.selectedNode.querySelector('.df-share')
        if (shareBtn) {
          shareBtn.addEventListener('click', this.showShareModal)
        }
      }
    })

    this.editor.on('nodeUnselected', () => {
      const delBtn = this.selectedNode.querySelector('.df-delete')
      delBtn.removeEventListener('click', this.onRemoveNodeClicked)
      onNodeUnselected(this.selectedNode, this.currentNode)

      // share node
      const shareBtn = this.selectedNode.querySelector('.df-share')
      if (shareBtn) {
        shareBtn.removeEventListener('click', this.showShareModal)
        onNodeUnselected(this.selectedNode, this.showShareModal)
      }
      this.selectedNode = null
    })

    this.editor.on('nodeRemoved', id => {
      delete this.properties[id]
      onNodeRemoved(id).then(success => {
        if (success) {
          // remove node from removedConnections if exists
          const index = this.removedConnections.indexOf(id)
          if (index !== -1) this.removedConnections.splice(index, 1)
        }
      })

      // remove node from removedConnections if exists
      const index = this.removedConnections.indexOf(id)
      if (index !== -1) this.removedConnections.splice(index, 1)
    })
    this.editor.on('connectionCreated', info => {
      const nodeInfo = this.editor.getNodeFromId(info.output_id)
      const outputConnections = nodeInfo.outputs[info.output_class].connections.length
      if (multipleOutputDisallowed(outputConnections, nodeInfo.name)) {
        this.editor.removeSingleConnection(info.output_id, info.input_id, info.output_class, info.input_class)
      }

      const inputNodeInfo = this.editor.getNodeFromId(info.input_id)
      const inputConnections = inputNodeInfo.inputs[info.input_class].connections.length
      if (multipleInputDisallowed(inputConnections, inputNodeInfo.name, nodeInfo.name)) {
        this.editor.removeSingleConnection(info.output_id, info.input_id, info.output_class, info.input_class)
      }

      // remove from removedConnections if exists
      const index = this.removedConnections.indexOf(info.input_id)
      if (index !== -1) this.removedConnections.splice(index, 1)
    })
    this.editor.on('connectionRemoved', ({ input_id }) => {
      this.removedConnections.push(input_id)
    })

    /* DRAG EVENT */

    /* Mouse and Touch Actions */

    // const elements = document.getElementsByClassName('drag-drawflow')
    // for (let i = 0; i < elements.length; i += 1) {
    //   elements[i].addEventListener('dragend', this.drop, false)
    //   elements[i].addEventListener('drag', this.positionMobile, false)
    //   elements[i].addEventListener('dragstart', this.drag, false)
    // }
  },
  methods: {
    ...mapActions('dataFlow', ['setDrawflowData']),
    setGateways(gateways) {
      this.initGateways(gateways)
    },
    onRemoveNodeClicked() {
      this.$bvModal.show('confirm-delete-modal')
    },
    removeNode() {
      this.editor.removeNodeId(this.selectedNode.getAttribute('id'))
    },
    toggleFullScreen(refName) {
      let elem = this.$refs[refName]

      if (elem._isVue) {
        elem = elem.$el
      }

      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        }
      } else {
        document.exitFullscreen()
      }
    },
    initializeDrawflow() {
      this.editor = new Drawflow(this.$refs.drawflow)
      this.editor.reroute = true
      this.editor.reroute_fix_curvature = true
      this.editor.force_first_input = false

      this.editor.start()
    },
    showButtons(id) {
      const nodeSelected = document.getElementById(`node-${id}`)

      if (nodeSelected) {
        this.selectedNode = nodeSelected
        this.currentNode = id
        const nodeType = getEntityType(nodeSelected.classList)

        this.selectedNodeObj = {
          uuid: id,
          name: nodeSelected.querySelector('input[df-template]').value,
          node_type: nodeType,
        }

        nodeSelected.querySelector('.node-label').style.left = '36px'

        // Show Add properties Button
        const btn = document.createElement('div')
        btn.classList.add('btn-success', 'centralise', 'drawflow-add', 'cursor-pointer')
        btn.innerHTML = '+'
        btn.addEventListener('click', () => {
          this.currentNodeProperty = this.properties[id] ?? {}
          this.modalComponent = setModalComponent(nodeType)

          this.$nextTick(() => {
            if (nodeHasOnlyDescription(nodeType)) {
              this.$bvModal.show('node-description-modal')
            } else this.$bvModal.show(`${nodeType}-modal`)
          })
        })

        nodeSelected.appendChild(btn)

        // Show Info Button
        const infoBtn = document.createElement('span')
        infoBtn.classList.add('btn-info', 'centralise', 'drawflow-info', 'cursor-pointer')
        infoBtn.innerHTML = 'i'
        infoBtn.addEventListener('click', () => {
          this.currentNode = id
          this.currentNodeProperty = this.properties[id] ?? {}

          this.$nextTick(() => {
            this.$bvModal.show('show-node-description-modal')
          })
        })

        nodeSelected.appendChild(infoBtn)
      }
    },
    transformObject(obj) {
      return Object.entries(obj).map(([key, value]) => ({
        [key]: value[0],
      }))
    },
    capitalizeWord(string) {
      return string.split('_').map(word => capitalize(word)).join(' ')
    },
    formNode(name, value, leftNode, rightNode, parentId, nodeType) {
      const joinInputs = []

      if (value.joined_nodes) {
        value.joined_nodes.forEach(node => {
          joinInputs.push({
            node: node.uuid,
            input: 'output_1',
          })
        })
      }

      return {
        ...value,
        id: value.uuid,
        uuid: value.uuid,
        class: `node ${getNodeClass(!!parentId, value.type, !!value.shares?.length)}`,
        data: {
          template: name,
        },
        html: getTemplate(value.type, parentId !== null ? this.capitalizeWord(value.type) : 'Gateway', value.uuid, value.shares?.[0]),
        name: parentId !== null ? value.type : 'GATEWAY',
        pos_x: parseInt(value.pos_x, 10),
        pos_y: parseInt(value.pos_y, 10),
        typenode: false,
        inputs: parentId !== null
          ? {
            input_1: {
              connections: [
                {
                  node: parentId,
                  input: nodeType === 'left-node' || nodeType === 'center' ? 'output_1' : 'output_2',
                },
                ...joinInputs,
              ],
            },
          }
          : {},
        outputs: {
          ...(leftNode?.length || hasOutputs(value.type ?? 'GATEWAY', 'left') ? {
            output_1: {
              connections: leftNode?.length
                ? leftNode.map(node => ({ node: node.uuid, output: 'input_1' })) : [],
            },
          } : {}),
          ...(rightNode?.length || hasOutputs(value.type, 'right') ? {
            output_2: {
              connections: rightNode?.length
                ? rightNode.map(node => ({ node: node.uuid, output: 'input_1' })) : [],
            },
          } : {}),
        },
      }
    },
    formatNodes(nodes, parentId = null, nodeType = 'gateway') {
      const grouped = parentId !== null
        ? { [nodes.uuid]: nodes }
        : this.transformObject(Object.groupBy(nodes, ({ name }) => name))
      const drawflow = {}

      return new Promise(resolve => {
        if (parentId !== null) {
          Object.values(grouped).forEach(({
            name, handler, next, positive, negative, join_nodes, ...value
          }) => {
            // eslint-disable-next-line camelcase
            let leftNode = handler || negative || join_nodes
            let rightNode = next || positive

            if (value.type === NODE_TYPES.workflow) {
              if (value.shares?.length) leftNode = null
            } else if (value.type === NODE_TYPES.branch) {
              leftNode = next
              rightNode = null
            }

            if (!Object.keys(this.properties).includes(value.uuid)) {
              this.properties[value.uuid] = {
                ...value,
                ...(value.shares ? {
                  shares: value.shares.map(share => share.uuid),
                } : []),
              }
            }

            drawflow[value.uuid] = this.formNode(name, value, leftNode ?? null, rightNode ?? null, parentId, nodeType)

            if (value.type === NODE_TYPES.branch) {
              next.forEach(branch => {
                this.formatNodes(branch, value.uuid, 'center').then(res => {
                  Object.values(res).forEach(item => {
                    drawflow[item.uuid] = res[item.uuid]
                  })
                })
              })
            } else {
              if (leftNode?.length) {
                this.formatNodes(leftNode[0], value.uuid, 'left-node').then(res => {
                  Object.values(res).forEach(item => {
                    if (Object.keys(drawflow).includes(item.uuid)) {
                      drawflow[item.uuid].inputs.input_1.connections.push(...item.inputs.input_1.connections)
                    } else drawflow[item.uuid] = res[item.uuid]
                  })
                })
              }
              if (rightNode?.length) {
                this.formatNodes(rightNode[0], value.uuid, 'right-node').then(res => {
                  Object.values(res).forEach(item => {
                    if (Object.keys(drawflow).includes(item.uuid)) {
                      drawflow[item.uuid].inputs.input_1.connections.push(...item.inputs.input_1.connections)
                    } else drawflow[item.uuid] = res[item.uuid]
                  })
                })
              }
            }
          })
        } else {
          grouped.forEach(group => {
            Object.entries(group).forEach(([key, {
              name, handler, next, positive, negative, ...value
            }]) => {
              this.properties[value.uuid] = value
              drawflow[key] = {
                data: {
                  [value.uuid]: this.formNode(name, value, handler ?? null, next ?? null, null, nodeType),
                },
              }

              if (handler?.length) {
                this.formatNodes(handler[0], value.uuid, 'left-node').then(res => {
                  Object.values(res).forEach(item => {
                    drawflow[key].data[item.uuid] = res[item.uuid]
                  })
                })
              }
              if (next?.length) {
                this.formatNodes(next[0], value.uuid, 'right-node').then(res => {
                  Object.values(res).forEach(item => {
                    drawflow[item.uuid] = res[item.uuid]
                  })
                })
              }
            })
          })
        }
        resolve(drawflow)
      })
    },
    formatNodesAndInitDrawflow(gatewayData, mode = null) {
      let data = {
        drawflow: {},
      }

      this.formatNodes(gatewayData).then(res => {
        if (mode) {
          data = this.$store.state.dataFlow.drawflowData
          data.drawflow[this.currentModule] = res[this.currentModule]
        } else {
          data.drawflow = res
        }

        this.$nextTick(() => {
          if (mode === 'module-changed') {
            const hasShareables = Object.values(res[this.currentModule]?.data).some(node => node.shareable)

            this.gateways[this.currentModule] = {
              gatewayUid: this.gateways[this.currentModule].gatewayUid,
              items: Object.values(res)?.[0]?.data,
              hasShareables,
            }
          } else {
            this.gateways = Object.entries(data.drawflow).reduce((acc, [key, value]) => {
              const hasShareables = Object.values(value?.data).some(node => node.shareable)
              const gatewayUid = Object.values(value?.data).find(item => item.class.includes('gateway')).uuid
              const filteredItems = Object.values(value?.data).filter(item => !item.class.includes('gateway'))

              acc[key] = {
                gatewayUid,
                items: filteredItems,
                hasShareables,
              }
              return acc
            }, {})
          }

          this.editor.import(data)
          this.setDrawflowData(data)
          if (!mode) this.getRoomId()
        })
      })
    },
    getRoomId() {
      if (Object.keys(this.gateways).includes(this.currentModule)) {
        useJwt.getGatewayRoomId(this.currentModule).then(response => {
          const { room } = response?.data
          this.roomIds[this.currentModule] = room
          this.currentRoomId = room

          this.$nextTick(() => {
            this.connectWS()
          })
        }).catch(error => {
          console.log(error)
        })
      }
    },
    getGateways() {
      const params = {
        service: this.currentService,
      }

      useJwt.getGateways({ params }).then(response => {
        this.initGateways(response?.data)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    initGateways(gatewayData) {
      if (!gatewayData) return

      if (gatewayData.length) {
        const gateways = gatewayData.map(d => d.name)

        if (gateways.length) {
          this.modules = []
        }

        gateways.forEach(gateway => {
          this.editor.addModule(gateway)
          this.modules.push(gateway)
        })

        let { currentModule } = this
        const { currentGateway } = this.$store.state.dataFlow
        if (currentGateway && gateways.includes(currentModule)) currentModule = currentGateway
        // eslint-disable-next-line prefer-destructuring
        else currentModule = this.modules[0]

        this.editor.changeModule(currentModule)
        this.currentModule = currentModule
        this.$store.dispatch('dataFlow/setCurrentGateway', currentModule)
        this.properties = {}

        this.formatNodesAndInitDrawflow(gatewayData)
        window.CHECK_EDITOR = this.editor
      } else {
        this.modules = []
        this.addNewModule()
      }
    },
    addNewModule() {
      this.jsonImportFile = null
      if (this.socket) this.socket.close()
      const moduleName = `Module ${this.modules.length + 1}`
      this.modules.push(moduleName)
      this.editor.addModule(moduleName)
      this.editor.changeModule(moduleName)
      this.$nextTick(() => {
        this.currentModule = moduleName
        this.addNodeToDrawFlow('GATEWAY', 480, 420)
        this.$store.dispatch('dataFlow/setCurrentGateway', null)
      })
    },
    positionMobile(ev) {
      this.mobile_last_move = ev
    },
    allowDrop(ev) {
      ev.preventDefault()
    },
    drag(ev) {
      if (ev.type === 'dragstart') {
        this.mobile_item_selec = ev.target.closest('.drag-drawflow').getAttribute('data-node')
      } else {
        ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'))
      }
    },
    drop(ev) {
      if (ev.type === 'drop') {
        const parentdrawflow = document.elementFromPoint(this.mobile_last_move.clientX, this.mobile_last_move.clientY).closest('#drawflow')
        if (parentdrawflow != null) {
          this.addNodeToDrawFlow(this.mobile_item_selec, this.mobile_last_move.clientX, this.mobile_last_move.clientY)
        }
        this.mobile_item_selec = ''
      } else {
        ev.preventDefault()
        const data = ev.dataTransfer.getData('node')
        this.addNodeToDrawFlow(data, ev.clientX, ev.clientY)
      }
    },
    addNodeToDrawFlow(name, posX, posY) {
      if (this.editor.editor_mode === 'fixed') {
        return
      }
      // eslint-disable-next-line no-param-reassign
      posX = posX * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)))
      // eslint-disable-next-line no-param-reassign
      posY = posY * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)))

      if (name) {
        this.editor.addNode(...getNode(posX, posY, name))
      }
    },
    changeModuleAndRemoveCurrentModule(indexToDelete) {
      if (this.modules.length) {
        const currentModule = this.modules[indexToDelete > 0 ? indexToDelete - 1 : indexToDelete + 1]
        if (currentModule) {
          this.currentModule = currentModule
          this.changeModule(currentModule)
        } else this.addNewModule()
      }

      this.$nextTick(() => {
        try {
          this.editor.removeModule(this.modules[indexToDelete])
        // eslint-disable-next-line no-empty
        } catch (e) {}
        this.modules.splice(indexToDelete, 1)

        if (!this.modules.length) this.isProcessing = false
      })
    },
    onRemoveConfirmed() {
      const workflow = this.$store.state.dataFlow.drawflowData.drawflow?.[this.currentModule]?.data
      if (workflow) {
        const gateway = Object.values(workflow).find(node => node?.data?.template === this.currentModule)
        this.isProcessing = true
        useJwt.deleteGateway(gateway.id).then(response => {
          this.showSuccessMessage(response)

          const indexToDelete = this.getCurrentModuleIndex(this.currentModule)
          this.changeModuleAndRemoveCurrentModule(indexToDelete)
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = true
        })
      } else {
        const indexToDelete = this.getCurrentModuleIndex(this.currentModule)
        this.changeModuleAndRemoveCurrentModule(indexToDelete)
      }
    },
    onDeleteModule() {
      if (this.gateways[this.currentGateway]?.hasShareables) {
        this.$bvModal.show('confirm-delete-shareable-modal')
      } else this.onRemoveConfirmed()
    },
    changeModuleAndInitializeRoom(selectedModule) {
      this.editor.changeModule(selectedModule)
      this.currentModule = selectedModule
      this.$store.dispatch('dataFlow/setCurrentGateway', selectedModule)

      const room = this.roomIds[this.currentModule]
      if (room) {
        this.currentRoomId = room
        this.connectWS()
      } else this.getRoomId()

      this.isProcessing = false
    },
    changeModule(selectedModule) {
      this.jsonImportFile = null
      if (this.gateways[this.currentModule]) {
        if (Object.values(this.gateways[this.currentModule]?.items).length) {
          this.changeModuleAndInitializeRoom(selectedModule)
        } else {
          this.isProcessing = true
          this.modules = this.modules.filter(module => Object.keys(this.gateways).includes(module))
          useJwt.getGateway(this.gateways[this.currentModule].gatewayUid).then(response => {
            this.formatNodesAndInitDrawflow([response?.data], 'module-changed')

            this.changeModuleAndInitializeRoom(selectedModule)
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      }
      this.editor.changeModule(this.currentModule)
    },
    getCurrentModuleIndex(selectedModule) {
      return this.modules.indexOf(selectedModule)
    },
    setNodeProperties(properties, field) {
      const currentProperties = this.properties[this.currentNode]
      if (this.currentNode) {
        if (field === 'shares') {
          if (currentProperties) {
            currentProperties[field] = properties[field]
            currentProperties.handler = properties[field]
          } else {
            this.properties[this.currentNode] = {
              ...properties,
              handler: properties[field],
              shares: properties[field],
            }
          }

          const contextMenu = this.selectedNode.querySelector('.more-options')
          if (contextMenu) {
            contextMenu.classList.add('d-none')
          }
        } else this.properties[this.currentNode] = properties
      }
    },
    clearCurrentModule() {
      this.editor.clearModuleSelected()
      this.addNodeToDrawFlow('GATEWAY', 480, 420)
    },
    onSave() {
      if (this.removedConnections.length) {
        this.$bvModal.show('unconnected-node-confirm-modal')
      } else this.updateDrawflow()
    },
    exportDrawflow() {
      const filename = `${this.currentModule}.json`
      const jsonStr = JSON.stringify(this.editor.export().drawflow[this.currentModule])

      const element = document.createElement('a')
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(jsonStr)}`)
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },
    importDrawflow(event) {
      const file = event.target.files[0]
      if (!file) {
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        try {
          const jsonStr = e.target.result
          const drawflowData = JSON.parse(jsonStr)
          Object.values(drawflowData.data).forEach(node => {
            this.properties[node.uuid] = node
          })
          this.editor.import({ drawflow: { [this.currentModule]: drawflowData } })
          this.showSuccess('Import Successful')
        } catch (error) {
          this.showError('Failed to import the data')
        }
      }
      reader.readAsText(file)
    },
    updateDrawflow() {
      this.isProcessing = true
      pushChangedService(this.currentService)
      bulkNodeDelete(this.removedConnections)
      saveDrawflow(
        this.editor.export().drawflow[this.currentModule],
        this.properties,
        this.currentService,
        !!this.jsonImportFile, // isImported
      ).then(success => {
        if (success) {
          this.showSuccess('Update Successful')
          setTimeout(() => {
            this.getGateways()
            this.removedConnections = []
          }, 200)
        }
      }).catch(error => {
        this.showErrorMessage(error)
        this.isProcessing = false
      })
    },
    connectWS() {
      if (this.socket) this.socket.close()

      const wsUrl = `${process.env.VUE_APP_WS_URL}/${this.currentModule}/${this.currentRoomId}`
      this.socket = new WebSocket(wsUrl)

      this.socket.onmessage = event => {
        const { message } = JSON.parse(event?.data)
        pushResponseMessage(message)
      }

      this.socket.onerror = event => {
        console.log(event)
      }
    },
    showShareModal() {
      this.currentNodeProperty = this.properties[this.currentNode] ?? {}
      this.$bvModal.show('shareNodeModal')
    },
  },
}
</script>

<style lang="scss">
.interactive-wrapper {
  position: fixed;
  right: 2%;
  top: 20%;
  z-index: 1000;

  .interactive-actions{
    background-color: #7367F0;

    .nav-link {
      background-color: #7367F0;
      color: #e6e6e6 !important;
    }
    .active {
      background-color: #5345ed !important;
      font-weight: 700;
    }
  }

  .show-chat-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 15;

    button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 0 12px;
    }
  }
}
.btn-group {
  span:not(:first-child) .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  span:not(:last-child) .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
