/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import useToast from '@/utils/toast'
import { NODE_TYPES, NODES } from './nodeConstants'
import useServices from './useServices'

const { showSuccess, showSuccessMessage, showErrorMessage } = useToast
const { pushChangedService } = useServices

let nodes = []
let singleRunWrapper = null
let familyRunWrapper = null

const getEntityType = classname => {
  const types = NODES

  return Object.values(classname).find(cls => types.indexOf(cls) !== -1)
}

const isUUID = value => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return uuidRegex.test(value)
}

export const closeContextMenu = node => {
  const singleRun = node.querySelector('.single-run-btn')
  const familyRun = node.querySelector('.family-run-btn')

  if (singleRun) singleRun.removeEventListener('click', singleRunWrapper)
  if (familyRun) familyRun.removeEventListener('click', familyRunWrapper)

  const contextMenu = node.querySelector('.more-options')
  contextMenu.classList.add('d-none')
}

const singleRunFunction = (selectedNode, nodeUid) => {
  closeContextMenu(selectedNode)
  store.dispatch('dataFlow/setCurrentExecutionData', {
    executionData: {
      mode: 'single',
      node: nodeUid,
    },
    selectedNode,
  })

  showSuccess('Single run mode activated!')
}

const familyRunFunction = (selectedNode, nodeUid) => {
  closeContextMenu(selectedNode)
  store.dispatch('dataFlow/setCurrentExecutionData', {
    executionData: {
      mode: 'family',
      node: nodeUid,
    },
    selectedNode,
  })
  showSuccess('Family run mode activated!')
}

const findNode = id => {
  const currentGateway = store.state.dataFlow.currentModule
  const { data } = store.state.dataFlow.drawflowData.drawflow?.[currentGateway]

  let node = null
  node = Object.values(data).find(n => n.id == id)

  if (!node) {
    node = Object.values(nodes).find(n => n.id == id)
  }

  return node
}

const createNodes = async (data, properties, service, isImported) => new Promise((resolve, reject) => {
  const promises = []
  const currentGateway = store.state.dataFlow.currentModule

  for (const nodeId in data) {
    const node = data[nodeId]
    const splitName = node.class.split(' ')[1].split('-')
    const nodeType = splitName.length > 1 ? splitName.slice(0, splitName.length - 1).join('') : splitName.join('')

    let storedNode = null
    const storedData = store.state.dataFlow.drawflowData.drawflow?.[currentGateway]?.data
    if (storedData) {
      storedNode = isUUID(nodeId) ? Object.values(storedData).find(n => n.uuid === nodeId) : Object.values(storedData).find(n => Number(n.id) === Number(nodeId))
    }
    const nodeProperties = properties[node.id]

    const uuid = storedNode?.uuid ?? node.uuid

    const form = {
      ...nodeProperties,
      ...(nodeProperties?.model && typeof nodeProperties?.model === 'object' ? {
        model: nodeProperties.model[0].canonicalName,
      } : {}),
      ...(uuid ? { uuid } : []),
      name: node.data.template,
      pos_x: Math.floor(node.pos_x),
      pos_y: Math.floor(node.pos_y),
      node_type: nodeType === 'sharedworkflow' ? NODE_TYPES.workflow : NODE_TYPES[nodeType],
    }

    if (isImported) {
      delete form.uuid
      delete form.data
    }

    let api = form.uuid ? 'updateNode' : 'createNode'
    if (form.node_type === NODE_TYPES.gateway) {
      const regex = /^(?!\/)(.*\/.*)(?<!\/)$/
      if (!regex.test(form.name)) reject(new Error('Gateway name should contain \'/\', but not at the start or at the end'))
      form.service = service
      api = form.uuid ? 'updateGateway' : 'createGateway'
      store.dispatch('dataFlow/setCurrentGateway', form.name)
    }

    let formData = new FormData()
    if (form.file) {
      for (const key in form) {
      // eslint-disable-next-line no-prototype-builtins
        if (form.hasOwnProperty(key)) {
          if (key === 'params') {
            formData.append(key, JSON.stringify(form[key]))
          } else formData.append(key, form[key])
        }
      }
    } else formData = form

    const promise = useJwt[api](formData).then(async response => {
      const nodeData = response.data.data
      const createdNode = findNode(nodeId)

      if (createdNode && nodeData) {
        createdNode.uuid = nodeData?.uuid
        await store.dispatch('dataFlow/updateNode', createdNode)
      }
    }).catch(error => {
      reject(error)
    })

    promises.push(promise)
  }

  Promise.all(promises)
    .then(() => resolve())
    .catch(error => reject(error))
})

export default {
  getEntityType,
  isUUID,
  saveDrawflow: async (jsonData, properties, service, isImported = false) => {
    const currentGateway = store.state.dataFlow.currentModule

    store.dispatch('dataFlow/createNewModule', currentGateway)
    nodes = jsonData.data
    const promises = []

    const success = await createNodes(nodes, properties, service, isImported)
      .then(async () => new Promise((resolve, reject) => {
        for (const nodeId in nodes) {
          const node = nodes[nodeId]
          const splitName = node.class.split(' ')[1].split('-')
          const type = splitName.join('_').toUpperCase()

          let currentNodeUid = nodeId
          if (!isUUID(currentNodeUid) || isImported) {
            currentNodeUid = findNode(nodeId)?.uuid
          }

          // For Workflow Node
          const workflowParams = {
            workflow: currentNodeUid,
          }

          // For Conditional Nodes
          const conditionalParams = {
            conditional: currentNodeUid,
          }

          // For Branch Nodes
          const branchParams = {
            branch: currentNodeUid,
            next: [],
          }

          // For Join Nodes
          const joinParams = {
            joined_node: [],
            node: currentNodeUid,
          }

          // Process output connections
          for (const outputKey in node.outputs) {
            const { connections } = node.outputs[outputKey]
            connections.forEach(async connection => {
              const targetNode = document.getElementById(`node-${connection.node}`)
              let childUid = connection.node
              if (!isUUID(childUid) || isImported) {
                const child = findNode(connection.node)
                childUid = child?.uuid
              }
              if (type === NODE_TYPES.gateway) {
                const splitNode = getEntityType(targetNode.classList).split('-')
                const nodeType = splitNode.length > 1 ? splitNode.slice(0, splitNode.length - 1).join('') : splitNode.join('')

                const gateway = findNode(node.id)

                const params = {
                  gateway: gateway?.uuid ?? node.id,
                  node: childUid,
                  node_type: nodeType === 'sharedworkflow' ? NODE_TYPES.workflow : NODE_TYPES[nodeType],
                }
                await useJwt.connectNodesWithGateway(params)
                  .catch(error => {
                    reject(error)
                  })
              } else if (type === NODE_TYPES.workflow || type === NODE_TYPES.sharedworkflow) {
                if (outputKey === 'output_1') workflowParams.handler = childUid
                else workflowParams.next = childUid
              } else if (type === NODE_TYPES.conditional || type === NODE_TYPES.gptconditional) {
                if (outputKey === 'output_1') conditionalParams.negative = childUid
                else conditionalParams.positive = childUid
              } else if (type === NODE_TYPES.branch) {
                branchParams.next.push(childUid)
              }
            })
          }

          // Process input connections for joins
          for (const inputKey in node.inputs) {
            const { connections } = node.inputs[inputKey]
            connections.forEach(async connection => {
              if (type === NODE_TYPES.join) {
                let joinedUid = connection.node
                const targetNode = document.getElementById(`node-${joinedUid}`)

                if (targetNode.classList.contains('gpt-node')) {
                  if (!isUUID(joinedUid) || isImported) {
                    const child = findNode(connection.node)
                    joinedUid = child?.uuid
                  }

                  joinParams.joined_node.push(joinedUid)
                }
              }
            })
          }

          let params
          let api
          let connect = false
          switch (type) {
            case NODE_TYPES.workflow:
            case NODE_TYPES.sharedworkflow:
              if (workflowParams.handler || workflowParams.next) {
                connect = true
                params = workflowParams
                api = 'connectWithWorkflow'
              }
              break

            case NODE_TYPES.conditional:
            case NODE_TYPES.gptconditional:
              if (conditionalParams.positive || conditionalParams.negative) {
                connect = true
                conditionalParams.node_type = type
                params = conditionalParams
                api = 'connectWithConditionals'
              }
              break

            case NODE_TYPES.branch:
              if (branchParams.next) {
                connect = true
                params = branchParams
                api = 'connectNodesWithBranch'
              }
              break

            case NODE_TYPES.join:
              connect = true
              params = joinParams
              api = 'connectNodesWithJoin'
              break
            default:
          }

          if (connect) {
            const promise = useJwt[api](params)
              .catch(error => {
                reject(error)
              })
            promises.push(promise)
          }
        }

        Promise.all(promises)
          .then(() => resolve(true))
          .catch(error => { reject(error) })
      }))

    return success
  },
  toggleContextMenu: (selectedNode, nodeUid = null, clickType = null) => {
    const options = selectedNode.querySelector('.more-options')

    // hide context menu on left click on the node
    if (clickType === 1) options.classList.add('d-none')

    // toggle context menu on right click
    else if (options.classList.contains('d-none')) {
      const singleRun = selectedNode.querySelector('.single-run-btn')
      const familyRun = selectedNode.querySelector('.family-run-btn')

      if (singleRun && nodeUid) {
        singleRunWrapper = () => singleRunFunction(selectedNode, nodeUid)
        singleRun.addEventListener('click', singleRunWrapper)
      }

      if (familyRun && nodeUid) {
        familyRunWrapper = () => familyRunFunction(selectedNode, nodeUid)
        familyRun.addEventListener('click', familyRunWrapper)
      }

      options.classList.remove('d-none')
    }
  },
  onNodeUnselected: selectedNode => {
    const infoBtn = selectedNode.querySelector('.drawflow-info')
    if (infoBtn) infoBtn.remove()

    const addBtn = selectedNode.querySelector('.drawflow-add')
    if (addBtn) addBtn.remove()

    const label = selectedNode.querySelector('.node-label')
    label.style.left = '16px'

    const options = selectedNode.querySelector('.more-options')
    options.classList.add('d-none')

    const singleRun = selectedNode.querySelector('.single-run-btn')
    const familyRun = selectedNode.querySelector('.family-run-btn')

    if (singleRun) singleRun.removeEventListener('click', singleRunFunction)
    if (familyRun) familyRun.removeEventListener('click', familyRunFunction)
  },
  onNodeRemoved: async nodeUid => {
    if (isUUID(nodeUid)) {
      return useJwt.deleteNode(nodeUid).then(response => {
        showSuccessMessage(response)
        pushChangedService(store.state.dataFlow.currentService)
      }).catch(error => {
        showErrorMessage(error)
      })
    }

    return false
  },
  bulkNodeDelete: nodeUids => {
    const newNodes = nodeUids.filter(uid => isUUID(uid))
    if (newNodes.length) {
      useJwt.bulkDeleteNode({ nodes: newNodes })
    }
  },
  onConnectionRemoved: (childUid, parentUid) => {
    if (isUUID(childUid) && isUUID(parentUid)) {
      useJwt.disconnectNodes({
        childUid,
        parentUid,
      }).then(response => {
        showSuccessMessage(response)
      }).catch(error => {
        showErrorMessage(error)
      })
    }
  },
}
